<template>
  <div
    class="shop-product-card-speaker"
    :class="{
      'is-event': item.type === 'event' || item.type === 'consultation',
    }"
  >
    <AtomImage
      v-if="item.speaker.block_author_image"
      :src="item.speaker.block_author_image"
      class="shop-product-card-speaker__image"
    />

    <div class="shop-product-card-speaker__name">
      {{ useTranslation('general', 'with') }}

      <span
        class="shop-product-card-speaker__link"
        @click="navigateToSpeaker($event)"
        @keypress="navigateToSpeaker($event)"
      >
        {{ item.speaker.name }}
      </span>
    </div>
  </div>
</template>

<script setup>
const item = inject('item');

const navigateToSpeaker = ($event) => {
    $event.stopPropagation();
    if (item.speaker?.sb_slug) {
        navigateTo(`../${item.speaker.sb_slug}`);
    }
};
</script>

<style lang="scss" scoped>
.shop-product-card-speaker {
    @include typo-font('regular');
    @include typo-size('a');

    display: flex;
    align-items: center;
    margin-bottom: 20px;
    column-gap: 10px;

    &.is-event {
        position: absolute;
        z-index: 9;
        bottom: 0;
        left: 0;
        padding: 11px 20px;
        border-radius: 0px 6px;
        margin-bottom: 0;
        background: $C_GREEN_BRIGHT;
        transform: translate3d(-1px, 1px, 0);
    }
}

.shop-product-card-speaker__image {
    overflow: hidden;
    width: 31px;
    height: 31px;
    border-radius: 50px;
}

.shop-product-card-speaker__link {
    color: $C_PRIMARY;
}
</style>
